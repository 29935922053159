import type { GetProductsBestsellersResponse } from '@vue-storefront/prestashop-api';
import { debounce } from 'lodash-es';
import { useSdk } from '~/sdk';

interface UseProductsBestsellers {
  data: GetProductsBestsellersResponse['psdata'];
  pending: boolean;
  error: string | null;
}

export const useProductsBestsellers = () => {
  const state = useState<UseProductsBestsellers>(() => ({
    data: {},
    pending: false,
    error: null,
  }));

  async function fetchProductsBestsellers() {
    state.value.pending = true;

    const { data, pending, error } = await useAsyncData(async () => {
      return await useSdk().commerce.getProductsBestsellers();
    });

    state.value.error = error.value?.message ?? null;
    state.value.data = data.value ?? { products: [] };
    state.value.pending = false;
  }

  return {
    ...toRefs(state.value),
    execute: fetchProductsBestsellers,
  };
};
