<template>
  <VContainer>
    <VSection :title="heading">
      <SfProgressCircular v-if="productsLoading" size="lg" />
      <Swiper
        v-else
        :breakpoints="{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
          1280: {
            slidesPerView: 4,
          },
        }"
        :space-between="20"
        navigation
        :pagination="{ clickable: true }"
        :modules="[Navigation, Pagination]"
        class="!pb-10"
      >
        <SwiperSlide v-for="product in products" :key="product.id">
          <VProductCard :product="product" :title="product.name" :image="getPsImage(product.primaryImage)" />
        </SwiperSlide>
      </Swiper>
    </VSection>
  </VContainer>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import { SfProgressCircular } from '@storefront-ui/vue';
import { getPsImage } from '@/composables/useCms';
import { useProductsBestsellers } from '~/composables/useProductsBestsellers';
import { useProductsLatest } from '~/composables/useProductsLatest';

const props = defineProps({
  heading: {
    type: String,
    default: null,
  },
  productsType: {
    type: String,
    default: 'featured',
  },
});

const { data, pending, execute } =
  props.productsType === 'latest'
    ? useProductsLatest()
    : props.productsType === 'bestSellers'
      ? useProductsBestsellers()
      : useProductsFeatured();

await execute();

const productsLoading = computed(() => pending.value);
const products = computed(() => data.value?.products ?? data.value ?? []);
</script>

<style scoped>
.swiper-pagination {
  @apply pt-8;
}
</style>
