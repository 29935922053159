import type { GetProductsLatestResponse } from '@vue-storefront/prestashop-api';
import { debounce } from 'lodash-es';
import { useSdk } from '~/sdk';

interface UseProductsLatest {
  data: GetProductsLatestResponse['psdata'];
  pending: boolean;
  error: string | null;
}

export const useProductsLatest = () => {
  const state = useState<UseProductsLatest>(() => ({
    data: {},
    pending: false,
    error: null,
  }));

  async function fetchProductsLatest() {
    state.value.pending = true;

    const { data, pending, error } = await useAsyncData(async () => {
      return await useSdk().commerce.getProductsLatest();
    });

    state.value.error = error.value?.message ?? null;
    state.value.data = data.value ?? { products: [] };
    state.value.pending = false;
  }

  return {
    ...toRefs(state.value),
    execute: fetchProductsLatest,
  };
};
