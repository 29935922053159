import type { GetProductsFeaturedResponse } from '@vue-storefront/prestashop-api';
import { useSdk } from '~/sdk';

interface UseProductsFeatured {
  data: GetProductsFeaturedResponse['psdata'];
  pending: boolean;
  error: string | null;
}

export const useProductsFeatured = () => {
  const state = useState<UseProductsFeatured>(() => ({
    data: {},
    pending: false,
    error: null,
  }));

  async function fetchProductsFeatured() {
    state.value.pending = true;

    const { data, pending, error } = await useAsyncData(async () => {
      return await useSdk().commerce.getProductsFeatured();
    });

    state.value.error = error.value?.message ?? null;
    state.value.data = data.value ?? { products: [] };
    state.value.pending = false;
  }

  return {
    ...toRefs(state.value),
    execute: fetchProductsFeatured,
  };
};
